import { createRouter, createWebHashHistory } from 'vue-router'
import { useStore } from 'vuex';
const store = useStore()

const routes = [
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: "工作台",
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: {
      title: "登录"
    }
  },
  {
    path: '/updatePassword',
    name: 'updatePassword',
    component: () => import('../views/login/updatePassword.vue'),
    meta: {
      title: "修改密码"
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  //   meta: {
  //     title: "物流管理"
  //   }
  // },
  // {
  //   path: '/dataStatistics',
  //   name: 'dataStatistics',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/information/dataStatistics.vue'),
  //   meta: {
  //     title: "数据统计",
  //     // keepAlive:true
  //   }
  // },
  // {
  //   path: '/media',
  //   name: 'media',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/information/media.vue'),
  //   meta: {
  //     title: "媒体列表",
  //     // keepAlive:true
  //   }
  // },
  // {
  //   path: '/extensionBit',
  //   name: 'extensionBit',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/information/extensionBit.vue'),
  //   meta: {
  //     title: "推广位管理",
  //     // keepAlive:true
  //   }
  // },
  // {
  //   path: '/dataExtension',
  //   name: 'dataExtension',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/information/dataExtension.vue'),
  //   meta: {
  //     title: "推广位统计",
  //     // keepAlive:true
  //   }
  // },
  {
    path: '/dataStatistics',
    name: 'dataStatistics',
    component: () => import(/* webpackChunkName: "about" */ '../views/information/dataStatistics.vue'),
    meta: {
      title: "数据统计",
      // keepAlive:true
    }
  },
  {
    path: '/media',
    name: 'media',
    component: () => import(/* webpackChunkName: "about" */ '../views/information/media.vue'),
    meta: {
      title: "媒体列表",
      // keepAlive:true
    }
  },
  {
    path: '/extensionBit',
    name: 'extensionBit',
    component: () => import(/* webpackChunkName: "about" */ '../views/information/extensionBit.vue'),
    meta: {
      title: "推广位管理",
      // keepAlive:true
    }
  },
  {
    path: '/dataExtension',
    name: 'dataExtension',
    component: () => import(/* webpackChunkName: "about" */ '../views/information/dataExtension.vue'),
    meta: {
      title: "推广位统计",
      // keepAlive:true
    }
  },
  {
    path: '/dataOrderShare',
    name: 'dataOrderShare',
    component: () => import(/* webpackChunkName: "about" */ '../views/information/dataOrderShare.vue'),
    meta: {
      title: "订单分享统计",
      // keepAlive:true
    }
  },


  {
    path: '/salesRatio',
    name: 'salesRatio',
    component: () => import(/* webpackChunkName: "about" */ '../views/base/salesRatio.vue'),
    meta: {
      title: "销售比例",
      // keepAlive:true
    }
  },
  {
    path: '/carousel',
    name: 'carousel',
    component: () => import(/* webpackChunkName: "about" */ '../views/base/carousel.vue'),
    meta: {
      title: "轮播图列表",
      keepAlive: true
    }
  },
  {
    path: '/carouselAdd',
    name: 'carouselAdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/base/carouselAdd.vue'),
    meta: {
      title: "轮播图添加"
    }
  },
  {
    path: '/publicSetting',
    name: 'publicSetting',
    component: () => import(/* webpackChunkName: "about" */ '../views/base/publicSetting.vue'),
    meta: {
      title: "公共配置"
    }
  },
  {
    path: '/publicSettingAdd',
    name: 'publicSettingAdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/base/publicSettingAdd.vue'),
    meta: {
      title: "公共配置添加"
    }
  },
  {
    path: '/expressSetting',
    name: 'expressSetting',
    component: () => import(/* webpackChunkName: "about" */ '../views/base/expressSetting.vue'),
    meta: {
      title: "快递配置"
    }
  },
  {
    path: '/blindDateKF',
    name: 'blindDateKF',
    component: () => import(/* webpackChunkName: "about" */ '../views/base/blindDateKF.vue'),
    meta: {
      title: "相亲客服"
    }
  },
  {
    path: '/blindDateKFAdd',
    name: 'blindDateKFAdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/base/blindDateKFAdd.vue'),
    meta: {
      title: "相亲客服添加"
    }
  },
  {
    path: '/expressList',
    name: 'expressList',
    component: () => import(/* webpackChunkName: "about" */ '../views/order/expressList.vue'),
    meta: {
      title: "快递订单",
      keepAlive: true
    }
  },
  {
    path: '/runlegList',
    name: 'runlegList',
    component: () => import(/* webpackChunkName: "about" */ '../views/order/runlegList.vue'),
    meta: {
      title: "同城订单",
      keepAlive: true
    }
  },
  {
    path: '/lightList',
    name: 'lightList',
    component: () => import(/* webpackChunkName: "about" */ '../views/order/lightList.vue'),
    meta: {
      title: "超轻订单",
      keepAlive: true
    }
  },
  {
    path: '/weightList',
    name: 'weightList',
    component: () => import(/* webpackChunkName: "about" */ '../views/order/weightList.vue'),
    meta: {
      title: "补缴列表",
      keepAlive: true
    }
  },
  {
    path: '/cashList',
    name: 'cashList',
    component: () => import(/* webpackChunkName: "about" */ '../views/order/cashList.vue'),
    meta: {
      title: "现结订单",
      keepAlive: true
    }
  },
  {
    path: '/userList',
    name: 'userList',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/userList.vue'),
    meta: {
      title: "用户列表"
    }
  },
  {
    path: '/blackList',
    name: 'blackList',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/blackList.vue'),
    meta: {
      title: "黑名单"
    }
  },
  {
    path: '/blackPhoneList',
    name: 'blackPhoneList',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/blackPhoneList.vue'),
    meta: {
      title: "手机号黑名单"
    }
  },
  {
    path: '/complaints',
    name: 'complaints',
    component: () => import(/* webpackChunkName: "about" */ '../views/sale/complaints.vue'),
    meta: {
      title: "投诉列表",
      keepAlive: true
    }
  },
  {
    path: '/complaintsNew',
    name: 'complaintsNew',
    component: () => import(/* webpackChunkName: "about" */ '../views/sale/complaintsNew.vue'),
    meta: {
      title: "投诉列表(新)",
      keepAlive: true
    }
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "about" */ '../views/sale/feedback.vue'),
    meta: {
      title: "工单列表",
      keepAlive: true
    }
  },
  {
    path: '/checkList',
    name: 'checkList',
    component: () => import(/* webpackChunkName: "about" */ '../views/sale/checkList.vue'),
    meta: {
      title: "核对订单",
      keepAlive: true
    }
  },
  {
    path: '/refundManage',
    name: 'refundManage',
    component: () => import(/* webpackChunkName: "about" */ '../views/sale/refundManage.vue'),
    meta: {
      title: "退款管理",
      keepAlive: true
    }
  },
  {
    path: '/complaintH5',
    name: 'complaintH5',
    component: () => import(/* webpackChunkName: "about" */ '../views/sale/complaintH5.vue'),
    meta: {
      title: "H5投诉列表"
    }
  },
  {
    path: '/userManagement',
    name: 'userManagement',
    component: () => import(/* webpackChunkName: "about" */ '../views/authority/userManagement.vue'),
    meta: {
      title: "用户管理",
      // keepAlive: true
    }
  },
  {
    path: '/roleList',
    name: 'roleList',
    component: () => import(/* webpackChunkName: "about" */ '../views/authority/roleList.vue'),
    meta: {
      title: "角色管理",
      // keepAlive: true
    }
  },
  {
    path: '/accountList',
    name: 'accountList',
    component: () => import(/* webpackChunkName: "about" */ '../views/distribute/accountList.vue'),
    meta: {
      title: "账户列表",
      // keepAlive: true
    }
  },
  {
    path: '/budgetDetail',
    name: 'budgetDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/distribute/budgetDetail.vue'),
    meta: {
      title: "收支明细",
      // keepAlive: true
    }
  },
  {
    // pathActive: '/posterAdd',
    path: '/posterList',
    name: 'posterList',
    component: () => import(/* webpackChunkName: "about" */ '../views/distribute/posterList.vue'),
    meta: {
      title: "海报列表",
      keepAlive: true
    }
  },
  {
    // pathActive: '/posterList',
    path: '/posterAdd',
    name: 'posterAdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/distribute/posterAdd.vue'),
    meta: {
      title: "海报添加",
    }
  },
  {
    path: '/scaleSetting',
    name: 'scaleSetting',
    component: () => import(/* webpackChunkName: "about" */ '../views/distribute/scaleSetting.vue'),
    meta: {
      title: "比例设置",
    }
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 全局前置守卫，这里可以加入用户登录判断
// router.beforeEach((to, from, next) => {
//   console.log(to)
//   console.log(from)
//   // 继续前进 next()
//   // 返回 false 以取消导航
//   next()
// })

// // // 全局后置钩子，这里可以加入改变页面标题等操作
router.afterEach((to, from) => {
  const _title = to.meta.title
  if (_title) {
    window.document.title = '快递后台管理--' + _title
  }
})

export default router
