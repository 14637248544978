import  request from "@/utils/request";

// login.vue
export function CaptchaImage(params) {
    return  request({
        url: "captchaImage",
        method: "get",
        params: params
    })
}

export function Login(params) {
    return  request({
        url: "login",
        method: "post",
        data: params
    })
}

// updatePassword.vue
export function UpdatePwd(params) {
    return  request({
        url: "sys/updatePwd",
        method: "post",
        data: params
    })
}


// 获取账号有几个小程序的权限
export function getMiniListApi(params) {
    return  request({
        url: "sys/user/preRole",
        method: "get",
        params: params
    })
}