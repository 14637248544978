import axios from "axios";
import { getStorage } from "@/utils/storage";
import { ElMessage } from 'element-plus'
let typeFile = null
//用来拦截用的
// axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded";
//创建一个单例
// console.log(process.env.VUE_APP_API)
const service = axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 120000,
    // headers: { "Content-Type": "application/json;charset=utf-8" },
})

//拦截器  -请求拦截
service.interceptors.request.use(config => {
    // console.log(config)
    //部分接口需要token
    let token = getStorage('birdToken')
    // console.log(token)
    if (token) {
        config.headers.Authorization = token
        // config.headers = {
        //     'Authorization': token,
        //     'userType': 'admin'
        // }
    }
    // if(config.data && config.data.ContentType){
    //     config.headers["Content-Type"] = config.data.ContentType
    // }
    config.headers.userType = 'admin'
    if (config.data && config.data.typeFile) {
        // config.responseType = 'blob'
        typeFile = config.data.typeFile
        delete config.data.typeFile
    }
    return config;
}, err => {
    return Promise.reject(err)
})

//拦截器  -响应拦截
service.interceptors.response.use(res => {
    if (typeFile) {
        return Promise.resolve(res)
    } else {
        if (res.data.code !== 200) {
            ElMessage({
                message: res.data.msg || 'Error',
                type: 'error',
                showClose: true
            })
        }
        // if(res.config.url != 'captchaImage' && res.config.url != 'login'){

        //     res.data.code = 210
        // }
        return Promise.resolve(res.data)
    }
    // if (res.data.code === 200) {
    //     return Promise.resolve(res.data)
    // } else {
    //     ElMessage({
    //         message: res.data.msg || 'Error',
    //         type: 'error',
    //         showClose: true
    //     })
    //     return Promise.resolve(res.data)
    // }
}, error => {
    if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        switch (error.response.status) {
            case 400:
                error.message = '错误请求'
                break
            case 401:
                error.message = '未授权，请重新登录'
                break
            case 403:
                error.message = '拒绝访问'
                break
            case 404:
                error.message = '请求错误,未找到该资源'
                // window.location.href = '/NotFound'
                break
            case 405:
                error.message = '请求方法未允许'
                break
            case 408:
                error.message = '请求超时'
                break
            case 500:
                error.message = '服务器端出错'
                break
            case 501:
                error.message = '网络未实现'
                break
            case 502:
                error.message = '网络错误'
                break
            case 503:
                error.message = '服务不可用'
                break
            case 504:
                error.message = '网络超时'
                break
            case 505:
                error.message = 'http版本不支持该请求'
                break
            default:
                error.message = `连接错误${error.response.status}`
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            ElMessage.error('服务器响应超时，请刷新当前页')
        }
        error.message = '连接服务器失败'
    }

    ElMessage.error(error.message)
    return Promise.resolve(error.response)
});

//整体导出
export default service;
