<template>
  <div class="app">
    <!-- 系统整体页面布局 -->
    <el-container v-if="isLogin">
      <!-- 左侧菜单栏部分 -->
      <el-aside :class="['el-aside', receiveFlag == true ? '' : 'el-aside_active']">
        <el-scrollbar height="98vh">
          <el-menu class="el-menu" background-color="#32323a" :unique-opened="false" :default-active="$route.path"
            text-color="#ffffff" router>
            <div class="aside_title" @click="receiveFlag = false">快递后台管理</div>
            <MenuTree :menuList="menuList"></MenuTree>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <div v-if="!receiveFlag" @click="receiveFlag = true" class="menu_receive">
        <el-icon>
          <CaretRight />
        </el-icon>
      </div>
      <el-container :class="receiveFlag == true ? 'el_container2' : 'el_container2_active'">
        <!-- 页面头部区域 高度默认60px -->
        <el-header class="el-header">
          <div class="header_fl flex_item flex_center_delj flex_wrap flex_ai_e">
            <div class="nav_list flex_center" v-for="(v, i) in navList" @click="onPages(i)">
              <div class="nav_name">{{ v.title }}</div>
              <!-- <el-icon @click.stop="navDel(i)">
                <CloseBold />
              </el-icon> -->
              <el-icon @click.stop="navDel(i)">
                <CircleCloseFilled />
              </el-icon>
            </div>
          </div>
          <div class="flex_center">
            <div class="header_list">
              <span>消息</span>
              <!-- <el-icon><ChatDotRound /></el-icon> -->
              <img src="./assets/images/message.png" alt="">
              <span class="header_red"></span>
            </div>
            <el-dropdown @command="handleCommand">
              <div class="header_list">
                <span>{{ username }}</span>
                <img src="./assets/images/setting.png" alt="">
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="password">修改密码</el-dropdown-item>
                  <el-dropdown-item command="exit">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>

          <!-- <el-popover placement="bottom" :width="100" trigger="hover">
          <div class="popover_list">
            <div class="popover_list_txt">修改密码</div>
            <div class="popover_list_txt">退出登录</div>
          </div>
          <template #reference>
            <div class="header_list">
              <span>设置</span>
              <img src="./assets/setting.png" alt="">
            </div>
          </template>
        </el-popover> -->
        </el-header>
        <el-divider />
        <!-- 右侧主题页面内容展示 -->
        <el-main class="el-mian">
          <!-- 路由页面 -->
          <router-view v-if="isRouterActive" v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
            </keep-alive>
            <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
          </router-view>
          <!-- <router-view v-if="isRouterActive"></router-view> -->
        </el-main>
        <el-footer class="flex_center">
          <el-icon style="color: #cccccc;transform: rotateY(180deg);">
            <Flag />
          </el-icon>
          <h1 class="el-footer-name">{{ nowYear }} 快递后台管理</h1>
          <el-icon style="color: #cccccc;">
            <Flag />
          </el-icon>
        </el-footer>
      </el-container>
    </el-container>
    <el-container class="el-container" v-else>
      <router-view></router-view>
    </el-container>

  </div>
</template>
<script setup>
import MenuTree from '@/components/menu/menustree.vue';
import { onMounted, provide, reactive, ref, toRefs, nextTick } from 'vue';
import { useRouter, useRoute } from "vue-router"
import { treeselect } from '@/api/authority'
import { formatTime } from '@/utils/public'
// import menuList from '@/router/routes'
import { ElMessage, ElNotification } from 'element-plus'
import { setStorage, getStorage, removeStorage } from "@/utils/storage"
import { useStore } from 'vuex';
const store = useStore()
const router = useRouter()
const route = useRoute()
const isRouterActive = ref(true)

const reload = () => {
  isRouterActive.value = false
  nextTick(() => {
    isRouterActive.value = true
  })
}
provide('reload', {
  reload
})

let dataForm = reactive({
  receiveFlag: true,
  isLogin: true,
  username: '',
  menuList: [{
    id: -1,
    parentId: 0,
    path: '/home',
    title: '工作台',
    icon: 'Menu'
  }],
  navList: [],
  // APPID: -99,
  nowYear: new Date().getFullYear()
})
const {
  receiveFlag,
  isLogin,
  username,
  menuList,
  navList,
  nowYear
} = toRefs(dataForm)
router.afterEach((to, from) => {
  if (to.name != 'login') {
    let obj = {
      title: to.meta.title,
      path: to.path
    }
    let flag = dataForm.navList.some(v => v.path == to.path)
    if (!flag) {
      if (dataForm.navList.length >= 10) {
        dataForm.navList.splice(0, 1)
        dataForm.navList.push(obj)
      } else {
        dataForm.navList.push(obj)
      }
    }
  }


  // 数组去重
  // new Set()去重
  //   const arr = [{name: 'Alice', age: 20}, {name: 'Bob', age: 30}, {name: 'Alice', age: 20}];
  // const uniqueArr = Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse);
  // console.log(uniqueArr);

  // reduce去重
  // const arr = [{ name: 'Alice', age: 20 }, { name: 'Bob', age: 30 }, { name: 'Alice', age: 20 }];
  // const uniqueArr = arr.reduce((result, obj) => {
  //   console.log(result)
  //   if (!result.some(item => JSON.stringify(item) === JSON.stringify(obj))) {
  //     result.push(obj);
  //   }
  //   return result;
  // }, []);
  // console.log(uniqueArr);


})
const navDel = (index) => {
  dataForm.navList.splice(index, 1)
}
const onPages = (index) => {
  router.push({
    path: dataForm.navList[index].path
  })
}
onMounted(() => {
  if(getStorage('flag1',1)){
    removeStorage('flag1')
  }
  // console.log(window.location.hash)
  dataForm.username = store.getters['user/getUsername']
  const birdToken = store.getters['user/getBirdToken']
  if (birdToken) {
    // 获取hash模式 #号后的部分 router.currentRoute在app.vue里面获取不到当前页面路由
    // token存在并且网址是登录页面,直接跳转到首页; 非登录页面就停留在当前页面
    if (window.location.hash == "#/login") {
      router.replace({
        path: '/'
      })
    }
    dataForm.isLogin = true
    // 获取appid列表储存在vuex

    treeselect().then(res => {
      if (res.code == 200) {
        if (res.data) {
          dataForm.menuList.push(...res.data)
          store.dispatch('role/setRoleListA', res.data)
        }
        reload()
        ElNotification({
          type: 'success',
          title: '登录成功',
          message: `<div>欢迎 ${dataForm.username} 登录</div><div>登录时间：${formatTime(Date.now(), 'YYYY-MM-DD hh:mm:ss')}</div>`,
          dangerouslyUseHTMLString: true,
          position: 'bottom-right',
        })
      } else if (res.code == 401) {
        // token过期 跳转登录页面
        router.replace({
          path: '/login'
        })
        dataForm.isLogin = false
        // 需要先跳转在删除token
        nextTick(() => {
          store.commit('user/removeExit')
        })
      }
    })
    router.beforeEach((to, from, next) => {
      //  因为每次切换路由都会走，所以判断下，防止每次切换页面都调接口
      let miniList = store.getters['role/getMiniList']
      if (miniList != '' && miniList != null && miniList != undefined) {
        next()
      } else {
        // 继续前进 next()
        // 返回 false 以取消导航
        store.dispatch('role/setMiniListA').then(res => {
          if (res.code == 200) {
            // dataForm.APPID = res.appid
            next()
          } else if (res.code == 401) {
            next()
          }
        })
      }

    })


  } else {
    dataForm.isLogin = false
    router.replace({
      path: '/login'
    })
  }
})
const handleCommand = (e) => {
  if (e == 'password') {
    router.push({
      path: '/updatePassword'
    })
  } else if (e == 'exit') {
    router.replace({
      path: '/login'
    })
    dataForm.isLogin = false
    // 需要先跳转在删除token
    nextTick(() => {
      store.commit('user/removeExit')
    })
    // window.location.replace("#/login")
  }
}

</script>

<style lang="less">
// @import url(./assets/css/public.less);

* {
  margin: 0;
  padding: 0;
}

.app {
  // min-height: 100vh;
  // background: #fff;
}

/*铺满屏幕，没有边距*/
.el-container {
  height: 100vh;
}


.aside_title {
  font-size: 24px;
  font-weight: bold;
  color: var(--main-white);
  text-align: center;
  margin: 20px 0;
}

// 左边标题
.el-aside {
  position: fixed;
  left: 0;
  top: 0;
  width: 200px !important;
  background-color: var(--header-bg);
  min-height: 100vh;
}

.el-menu {
  width: 200px !important;
  min-height: 100%;
}

.el-aside_active {
  width: 0 !important;
}

// 展开菜单图标
.menu_receive {
  position: fixed;
  z-index: 999;
  left: -32px;
  top: 38px;
  color: #32323a;
  font-size: 80px;
}

.el_container2 {
  margin-left: 200px;
}

.el_container2_active {
  margin-left: 0;
}

.el-header {
  /* 顶部部分的高度(默认60px) */
  background-color: var(--main-white);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 60px !important;

  .nav_list {
    transition: .5s;
    margin: 0 10px 0 0;
    padding: 8px 10px;
    box-shadow: 0px 0px 10px #999999;
    border-radius: 36px;
    color: #333333;

    .nav_name {
      font-size: 14px;
      font-weight: 600;
      margin-right: 6px;
    }

    &:hover {
      color: #ffffff;
      transition: .5s;
      background-color: #3291FF;
    }
  }
}

// .popover_list {
//   .popover_list_txt {
//     width: 100%;
//     height: 50px;
//     font-size: 20px;
//     text-align: center;
//   }
// }

.header_list {
  cursor: pointer;
  color: var(--main-black);
  display: flex;
  align-items: center;
  margin-left: 30px;
  font-size: 18px;
  position: relative;

  span {
    margin-right: 6px;
  }

  img {
    width: 26px;
    height: 26px;
  }

  .header_red {
    position: absolute;
    right: -8px;
    top: -2px;
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
  }
}





.el-mian {
  background-color: var(--main-white);
}

.el-footer {
  height: 46px !important;
}

.el-footer-name {
  margin: 0 10px;
  font-size: 18px;
  font-weight: 500;
  color: #cccccc;
}
</style>
