// 处理 element 图片
import { ElMessage } from 'element-plus'

export function processingImg(arr) {
    let list = []
    if (arr) {
        arr.forEach(v => {
            if (v.response) {
                list.push(v.response.data.url)
            } else {
                list.push(v.url)
            }
        });
    }
    return list.toString()
}
export function processingImgList(arr) {
    let list = []
    if (arr) {
        arr.forEach(v => {
            if (v.response) {
                list.push(v.response.data)
            } else {
                list.push(v)
            }
        });
    }
    return list
}

export function onCopy(content) {
    let oInput = document.createElement('input');
    oInput.value = content;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象;
    document.execCommand("Copy"); // 执行浏览器复制命令
    ElMessage({
        message: '复制成功',
        type: 'success',
    })
    oInput.remove()
}


// 转换时间
export function formatTime(timer, timeFormat) {
    // time 时间戳  timeFormat 传入时间格式 例：YYYY-MM-DD hh:mm:ss
    let time = new Date(timer)
    let result = ''
    if (timeFormat.indexOf("YYYY") !== -1) {
        result += time.getFullYear()
    }
    if (timeFormat.indexOf("MM") !== -1) {
        let MM = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
        result += '-' + MM
    }
    if (timeFormat.indexOf("DD") !== -1) {
        let DD = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
        result += '-' + DD
    }
    if (timeFormat.indexOf("hh") !== -1) {
        let hh = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
        result += ' ' + hh
    }
    if (timeFormat.indexOf("mm") !== -1) {
        let mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
        result += ':' + mm
    }
    if (timeFormat.indexOf("ss") !== -1) {
        let ss = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()
        result += ':' + ss
    }
    if (timeFormat.includes('/')) {
        result = result.replace(/-/g, '/')
    }
    return result
}
// export function formatTime(timer, timeFormat) {
//     // time 时间戳  timeFormat 传入时间格式 例：YYYY-MM-DD hh:mm:ss
//     let time = new Date(timer)
//     let result = ''
//     if (timeFormat.indexOf("YYYY") !== -1) {
//         result += time.getFullYear() + '-'
//     }
//     if (timeFormat.indexOf("MM") !== -1) {
//         let MM = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
//         result += MM + '-'
//     }
//     if (timeFormat.indexOf("DD") !== -1) {
//         let DD = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
//         result += DD
//     }
//     if (timeFormat.indexOf("hh") !== -1) {
//         let hh = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
//         result += ' ' + hh + ':'
//     }
//     if (timeFormat.indexOf("mm") !== -1) {
//         let mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
//         result += mm + ':'
//     }
//     if (timeFormat.indexOf("ss") !== -1) {
//         let ss = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()
//         result += ss
//     }
//     if (timeFormat.includes('/')) {
//         result = result.replace(/-/g, '/')
//     }
//     return result
// }

// 金额加正负 人民币符号
export function getPriceStr(price, flag) {
    // flag: 1 拼+号（收入支出）； 2不拼加号(余额)
    let num = parseFloat(price)
    if (isNaN(num) == true) {
        return 0
    } else {
        if (flag == 1) {
            if (num > 0) {
                return `+￥${num}`
            } else if (num < 0) {
                let str = num.toString()
                str = str.replace(/\-/g, '')
                return `-￥${str}`
            } else if (num == 0) {
                return `￥${num}`
            }
        } else if (flag == 2) {
            if (num > 0) {
                return `￥${num}`
            } else if (num < 0) {
                let str = num.toString()
                str = str.replace(/\-/g, '')
                return `-￥${str}`
            } else if (num == 0) {
                return `￥${num}`
            }
        }
    }
}


export function getMiniName(appid) {
    let name = ''
    if (appid == 1) {
        name = '快递便宜'
    } else if (appid == 2) {
        name = '快递发件'
    } else if (appid == 3) {
        name = '寄快递省'
    } else if (appid == 4) {
        name = '快递低价寄'
    } else if (appid == 5) {
        name = '实惠寄'
    } else if (appid == 6) {
        name = '快递实惠寄'
    } else if (appid == 7) {
        name = '快递低价'
    } else if (appid == 8) {
        name = '赛鸟快递'
    } else if (appid == 9) {
        name = '快递优惠鸟'
    }

    return name
}