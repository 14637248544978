import { createStore } from 'vuex'
import user from './modules/user'
import role from './modules/role'
export default createStore({
  // state: {
  // },
  // getters: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    user,
    role
  }
})
