// console.log(store)
// console.log(store.state.user.birdToken)
// console.log(store.getters['user/getBirdToken'])
// store.commit('user/setBirdToken', 666)
// store.dispatch('user/setBirdTokenA', 666)
import { getMiniListApi } from '@/api/login';

const state = {
    roleList: [], //角色列表
    miniList: [], //小程序列表
    APPID: -99, //小程序账号登录权限 
    // '' 字符是admin 1 快递便宜 2 快递发件 3 寄快递省 4 快递低价寄 5 实惠寄 6 快递实惠寄 7 快递低价 8 赛鸟快递 9 快递优惠鸟
}
const getters = {
    getRoleList: state => state.roleList,
    getMiniList: state => state.miniList,
    getAPPID: state => state.APPID,
}
const mutations = {
    setRoleList(state, value) {
        state.roleList = value
    },
    setMiniList(state, value) {
        state.miniList = value
    },
    setAPPID(state, value) {
        state.APPID = value
    },

}
const actions = {
    setRoleListA(context, value) {
        context.commit('setRoleList', value)
    },
    setMiniListA(context, value) {
        return new Promise((resolve, reject) => {
            getMiniListApi().then(res => {
                if (res.code == 200) {
                    context.commit('setMiniList', res.data)
                    context.commit('setAPPID', res.appid)
                    // let result = res.data
                    // if(appidList == "admin"){
                    //     context.commit('setMiniList', result)
                    // }else{
                    //     appidList = appidList.split(',')
                    //     result.forEach(v => {
                    //         appidList.forEach(val => {
                    //             if(v.id == val){
                    //                 v.disabled = true
                    //             }
                    //         })
                    //     });
                    //     context.commit('setMiniList', result)
                    // }
                }
                resolve(res)
            })
        });

    },

}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}