// encodeURIComponent/btoa 加密 decodeURIComponent/atob 解密
// btoa /atob base64加密(不能加密中文) 

import { Encrypt, Decrypt } from '../utils/aes';


export function getStorage(key, type) {
    // return JSON.parse(sessionStorage.getItem(key))
    // let value = localStorage.getItem(key)
    // if (value != '' && value != null && value != undefined) {
    //     return JSON.parse(value)
    // } else {
    //     return null
    // }
    // if (localStorage.getItem('flag1')) {
    //     let value = localStorage.getItem(key)
    //     if (value != '' && value != null && value != undefined) {
    //         // type无值代表解密
    //         if (type) {
    //             return value
    //         } else {
    //             return Decrypt(value)
    //         }
    //     } else {
    //         return ''
    //     }
    // } else {
    //     localStorage.clear()
    //     localStorage.setItem('flag1', true)
    // }
    let value = localStorage.getItem(key)
    if (value != '' && value != null && value != undefined) {
        // type无值代表解密
        if (type) {
            return value
        } else {
            return Decrypt(value)
        }
    } else {
        return ''
    }

}

export function setStorage(key, value, type) {

    // sessionStorage.setItem(key, JSON.stringify(value))
    // if (value != '' && value != null && value != undefined) {
    //     localStorage.setItem(key, JSON.stringify(value))
    // }
    if (value != '' && value != null && value != undefined) {
        // type无值代表加密
        if (type) {
            localStorage.setItem(key, value)
        } else {
            localStorage.setItem(key, Encrypt(value))
        }
    }


    // if (value != '' && value != null && value != undefined) {
    //     let txt = window.btoa(encodeURIComponent(value))
    //     localStorage.setItem(key, txt)
    // }

}

export function removeStorage(key) {
    // sessionStorage.removeItem(key)
    localStorage.removeItem(key)
}
