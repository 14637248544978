import request from "@/utils/request";


// 菜单列表
export function treeselect(params) {
    return request({
        url: "sys/menu/treeselect",
        method: "get",
        params: params
    })
}
// 添加/修改角色
export function addRoleAndMeun(params) {
    return request({
        url: "sys/role/addRoleAndMeun",
        method: "post",
        data: params
    })
}
// 角色列表
export function roleListApi(params) {
    return request({
        url: "sys/role/list",
        method: "get",
        params: params
    })
}
// 删除角色
export function roleDelete(params) {
    return request({
        url: "sys/role/delete",
        method: "post",
        data: params
    })
}

// 用户管理
// 用户列表
export function usersList(params) {
    return request({
        url: "sys/users/list",
        method: "get",
        params: params
    })
}
// 添加/修改
export function usersAdd(params) {
    return request({
        url: "sys/users/add",
        method: "post",
        data: params
    })
}
// 删除用户
export function usersDelete(params) {
    return request({
        url: "sys/users/delete",
        method: "post",
        data: params
    })
}
// 重置密码
export function usersResetPwd(params) {
    return request({
        url: "sys/users/resetPwd",
        method: "post",
        data: params
    })
}
// 修改密码
export function updatePwdByAdmin(params) {
    return request({
        url: "sys/updatePwdByAdmin",
        method: "post",
        data: params
    })
}

// 强制下线。强制删除缓存里得token
export function usersDownLine(params) {
    return request({
        url: "sys/users/downLine",
        method: "post",
        data: params
    })
}