import { setStorage, getStorage,removeStorage } from "@/utils/storage"
// console.log(store)
// console.log(store.state.user.birdToken)
// console.log(store.getters['user/getBirdToken'])
// store.commit('user/setBirdToken', 666)
// store.dispatch('user/setBirdTokenA', 666)
const state = {
    birdToken: getStorage('birdToken') || '',
    username: getStorage('username') || '',
    userId: getStorage('userId') || '',
    // appidList: getStorage('appidList') || ''
}
const getters = {
    getBirdToken: state => state.birdToken,
    getUsername: state => state.username,
    getUserId: state => state.userId,
    // getappidList: state => state.appidList,
}
const mutations = {
    setBirdToken(state, value) {
        // state.birdToken = value
        setStorage('birdToken', value)
    },
    setUsername(state, value) {
        // state.username = value
        setStorage('username', value)
    },
    setUserId(state, value) {
        // state.username = value
        setStorage('userId', value)
    },
    // setappidList(state, value) {
    //     // state.appidList = value
    //     setStorage('appidList', value)
    // },
    removeExit(state,value){
        state.birdToken = ''
        state.username = ''
        state.userId = ''
        removeStorage('birdToken')
        removeStorage('username')
        removeStorage('userId')
    }
}
const actions = {
    setBirdTokenA(context, value) {
        context.commit('setBirdToken', value)
    },
    setUsernameA(context, value) {
        context.commit('setUsername', value)
    },
    setUserIdA(context, value) {
        context.commit('setUserId', value)
    },
    // setappidListA(context, value) {
    //     context.commit('setappidList', value)
    // }
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}